import React from "react"
import { isBrowser } from "../state/utils"
import Layout from "../components/layout"

const browser = isBrowser()

const NotFoundPage = () =>
  browser && (
    <Layout>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )

export default NotFoundPage
